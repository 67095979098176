.agency-filters {
  &__container {
    width: auto;
    height: 60px;
    padding: 10px 15px;
    margin: 10px -15px;
    overflow-y: hidden;
    display: flex;
    overflow-x: auto;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__container::-webkit-scrollbar {
    display: none;
  }

  &__item {
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    min-width: fit-content;
    height: 37px;
    line-height: 1;
    margin-right: 5px;
    background: #fff;
    border: 1px solid #eae5e1;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #2f1f19;

    &__active {
      background: #4264fd;
      color: #fff;
      border-width: 0;
    }
  }

  &__results {
    &-block {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
      margin: 0 auto 10px;
      position: relative;
      width: 100%;
      background: #fff;

      &__header {
        width: 100%;
        margin-bottom: 15px;
        color: #2f1f19;
        font-size: 16px;
        font-weight: 500;
      }

      &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  &__results-button {
    background: #fff;
    width: 100%;
    color: #4264fd;
    margin: 5px 0 10px;
    font-size: 15px;
    padding: 15px;
    border-radius: 7px;
    border: 1px solid #eae5e1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-decoration: none;
  }
}
