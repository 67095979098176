.item-schedule {
  margin-bottom: 20px;

  &__timetable {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding-left: 26px;
    color: #2f1f19;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;

    &::before {
      background-image: url('/static/images/clock.svg');
      content: '';
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}
