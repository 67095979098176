.agency-page {
  background-color: #fff;
  padding: 0 15px;

  &__content {
    padding: 20px 0;
    border-top: solid 1px #eae5e0;
    border-bottom: solid 1px #eae5e0;
  }
}

.agency-header-section {
  border-radius: 2px;
  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-family: $helvetica;
  margin-bottom: 20px;

  &__banner {
    position: relative;
    overflow: hidden;
    height: 100px;
    width: 100%;
  }

  &__info-wrap {
    display: flex;
    padding: 0 15px;
    margin: 20px 0;
  }

  &__logo {
    min-width: 80px;
    height: 80px;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 15px;
    align-self: center;
  }

  &__info {
    align-self: center;

    &-row {
      display: block;
    }

    &-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      color: #2f1f19;
      margin: 0 0 5px;
    }

    &-views {
      font-size: 14px;
      color: #98918a;
      margin-bottom: 2px;
    }
  }

  &__description {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 25px;
    color: #2f1f19;
    font-weight: 400;
  }

  &__prune {
    overflow: hidden;
    display: -webkit-box; // stylelint-disable-line  value-no-vendor-prefix
    -webkit-line-clamp: 6;
    line-clamp: 6;
    -webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
  }

  &__read-more {
    padding-left: 15px;
    font-weight: 400;
    font-size: 15px;
    text-decoration-line: underline;
    color: #4264fd;
  }
}
