.row-location {
  margin-bottom: 15px;

  &__address {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding-left: 26px;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;

    a {
      color: #4264fd;
      cursor: pointer;
    }

    &::before {
      background-image: url('/static/images/map_row.svg');
      content: '';
      width: 16px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
}
